/**
 * @generated SignedSource<<011d3c291967c938ae88af866d069722>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersApplicationBase$data = {
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckIsPlusPage" | "helpersCheckIsPostiesArticle" | "helpersCheckIsPostiesPage">;
  readonly " $fragmentType": "helpersApplicationBase";
};
export type helpersApplicationBase$key = {
  readonly " $data"?: helpersApplicationBase$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersApplicationBase">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersApplicationBase"
};

(node as any).hash = "92b58be8ee76666f5e3c3e0e894ce7a2";

export default node;
