/**
 * @generated SignedSource<<aec74c56c884e8fcbcc33e83de9730de>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersGetTypeNameNode$data = {
  readonly __typename: string;
  readonly entityId: string;
  readonly parentSection?: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckCanShowInPlusPlatformContent" | "helpersCheckIsNewsAgendaTopicTopic" | "helpersCheckIsPlusArticle">;
  readonly " $fragmentType": "helpersGetTypeNameNode";
};
export type helpersGetTypeNameNode$key = {
  readonly " $data"?: helpersGetTypeNameNode$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersGetTypeNameNode">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersGetTypeNameNode"
};

(node as any).hash = "0f61aba8d546a23246af7be8724d6a4d";

export default node;
