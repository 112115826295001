/**
 * @generated SignedSource<<fd309d93a485c807144ebeee5db9a8cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type atomsCurrentArticleAtomBase$data = {
  readonly __typename: "Article";
  readonly " $fragmentSpreads": FragmentRefs<"articleSeoArticle" | "articleShareWidgetArticle" | "contentShareWidgetContent" | "headerContentBookmarkArticle" | "headerContentCommentTrigger" | "helpersCheckIsStyleArticle" | "helpersUseSharingUrl" | "hooksContentInterestArticle" | "hooksTrackPageViewArticle" | "hooksUseCurrentArticleIdsArticle" | "hooksUseNotificationReadStatusArticle" | "onboardingNewsletterSubscriptionArticle">;
  readonly " $fragmentType": "atomsCurrentArticleAtomBase";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "atomsCurrentArticleAtomBase";
};
export type atomsCurrentArticleAtomBase$key = {
  readonly " $data"?: atomsCurrentArticleAtomBase$data;
  readonly " $fragmentSpreads": FragmentRefs<"atomsCurrentArticleAtomBase">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "atomsCurrentArticleAtomBase"
};

(node as any).hash = "fb923eac8eecf7e57362308716e830d3";

export default node;
